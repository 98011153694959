import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "2",
      height: "11",
      x: "11",
      y: "4",
      rx: "1"
    }, null, -1),
    _createElementVNode("rect", {
      width: "2",
      height: "2.5",
      x: "11",
      y: "18",
      rx: "1"
    }, null, -1)
  ])))
}
export default { render: render }