import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m3.47 12.53 5 5a.75.75 0 0 0 1.06 0L20.536 6.525a.75.75 0 0 0 0-1.061.75.75 0 0 0-1.061 0L9 15.939 4.53 11.47a.749.749 0 1 0-1.06 1.06" }, null, -1)
  ])))
}
export default { render: render }