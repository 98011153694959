import { PAYMENT_ICONS } from '@assets/constants/inline-svg'
import { MESSAGE_SEVERITY, MESSAGE_TYPE } from '@base/constants/message'

export interface PaymentMethodIcon {
  name: string
  class: string
  type: InlineSvgType
}

export default function usePaymentMethod() {
  const route = useRoute()
  const router = useRouter()
  const { add: addMessage } = useMessage()
  const { design } = useDesign()

  const paymentMethodsIcons = computed(() => ({
    Paypal: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.PAYPAL_DARK
        : PAYMENT_ICONS.PAYPAL_LIGHT,
      class: 'h-6',
    },
    Amazon: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.AMAZON_DARK
        : PAYMENT_ICONS.AMAZON_LIGHT,
      class: 'h-5',
    },
    GooglePay: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.GPAY_LIGHT
        : PAYMENT_ICONS.GPAY_DARK,
      class: 'h-6',
    },
    Kps: {
      name: PAYMENT_ICONS.CREDITCARD,
      class: 'h-6',
    },
    Sofort: {
      name: PAYMENT_ICONS.UNZER,
      class: 'h-4',
    },
    Klarna: {
      name: PAYMENT_ICONS.KLARNA,
      class: 'h-8',
    },
    Loyalty: {
      name: PAYMENT_ICONS.LOYALTY,
      class: 'h-8',
    },
  }))

  const paymentParams = ref<Record<string, string>>({})

  function handlePaymentParams(prefix: string) {
    const query = route.query
    const paymentEntries = Object.entries(query).filter(([key]) =>
      key.toLowerCase().includes(prefix.toLowerCase())
    )

    if (paymentEntries.length) {
      paymentParams.value = Object.fromEntries(
        paymentEntries.map(([key, value]) => [key, value?.toString() ?? ''])
      )

      const cleanParams = new URLSearchParams()
      Object.entries(query).forEach(([key, value]) => {
        if (
          !key.toLowerCase().includes(prefix.toLowerCase()) &&
          value !== null
        ) {
          cleanParams.append(key, value.toString())
        }
      })

      router.replace(`${route.path}?${cleanParams.toString()}`)
    }

    return paymentParams.value
  }

  function getPaymentIcon(key: string): PaymentMethodIcon {
    const icon =
      paymentMethodsIcons.value[key as keyof typeof paymentMethodsIcons.value]
    return {
      ...icon,
      type: INLINE_SVG_TYPE.PAYMENT_ICON,
      class: `inline-flex w-auto shrink-0 ${icon.class}`,
    }
  }

  function getReturnUrl(path?: string): string {
    return `${window.location.origin}${path || route.fullPath}`
  }

  function displayError({
    message,
    type = MESSAGE_TYPE.TOAST,
  }: {
    message: string
    type?: MessageType
  }) {
    addMessage({
      message,
      severity: MESSAGE_SEVERITY.ERROR,
      type,
    })
  }

  return {
    getPaymentIcon,
    getReturnUrl,
    displayError,
    handlePaymentParams,
    paymentParams,
  }
}
