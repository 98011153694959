import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13 21.951S12.337 22 12 22C6.481 22 2 17.519 2 12S6.481 2 12 2c.337 0 1 .049 1 .049v10.014l-2.876 2.876-2.594-2.593a.749.749 0 1 0-1.06 1.06l3.124 3.124a.75.75 0 0 0 1.06 0L13 14.185z" }, null, -1),
    _createElementVNode("path", { d: "m11 16.185 6.53-6.531a.749.749 0 1 0-1.06-1.06L11 14.063V2.049S11.663 2 12 2q.506 0 1 .049c5.05.503 9 4.769 9 9.951s-3.95 9.448-9 9.951q-.494.048-1 .049c-.337 0-1-.049-1-.049z" }, null, -1)
  ])))
}
export default { render: render }