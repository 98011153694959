import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M4 20a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12-3 7a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm16-1.5a.5.5 0 0 0 .5-.5V8.136l-8.136 4.52-.021.011-.022.011-.022.01-.022.009-.022.008-.023.008-.022.007-.023.006-.023.006-.023.005-.024.004-.023.003-.023.003-.024.002-.023.001h-.048l-.023-.001-.024-.002-.023-.003-.023-.003-.024-.004-.023-.005-.023-.006-.023-.006-.022-.007-.023-.008-.022-.008-.022-.009-.022-.01-.022-.011-.021-.011L3.5 8.136V18a.5.5 0 0 0 .5.5zM4 5.5a.5.5 0 0 0-.5.5v.42l8.5 4.722 8.5-4.722V6a.5.5 0 0 0-.5-.5z" }, null, -1)
  ])))
}
export default { render: render }