<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationShare v-if="order?.show?.isBookableOrReservable" :order />
    <OrderConfirmationOrderOverviewTicket :order :purchase-data />
    <OrderConfirmationCodes :order />
    <OrderConfirmationConcessions
      v-if="order.concessions.length"
      :order-concessions="order.concessions"
    />
    <OrderConfirmationCancellation :order />
    <OrderConfirmationAgeRating :order />
    <OrderConfirmationCustomNotes />

    <div
      class="mt-12 font-bold print:!text-black print:dark:!text-black"
      v-html="ct('THANKYOU_H3', null, t('haveFunText'))"
    />

    <NuxtLink
      v-if="showBackHomeLink"
      class="flex flex-row items-center underline print:hidden"
      :to="
        localeRoute({
          name: ROUTE_NAMES.CINEMA_PROGRAM,
          params: {
            citySlug: order.cinema!.city.urlSlug,
            cinemaSlug: order.cinema!.urlSlug,
          },
        }) as RouteLocationRaw
      "
    >
      <InlineSvg :name="SVG_ICON.CHEVRON_LEFT" class="size-5" />
      <span v-text="t('backHome', { cinema: order.cinema!.name })" />
    </NuxtLink>

    <RoktWidget
      v-if="order.roktIntegration"
      :attributes="order.roktIntegration"
    />
  </div>
</template>

<script setup lang="ts">
import { type RouteLocationRaw } from 'vue-router'
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'
import { ROUTE_NAMES } from '@portal/constants/routes'
import { SVG_ICON } from '@assets/constants/inline-svg'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()
const localeRoute = useLocaleRoute()

const showBackHomeLink = computed(() => {
  // TODO: Show when in widget mode
  return false
})

defineOptions({
  name: 'OrderConfirmationTypeTicket',
})
</script>

<i18n>
de:
  haveFunText: "Viel Spaß beim Film."
  backHome: 'Zurück zur Auswahl von {cinema}'
es:
  haveFunText: "¡Disfruta de la película!"
  backHome: 'Volver a la selección desde {cinema}'
en:
  haveFunText: "Have fun at your next cinema visit!"
  backHome: 'Back to the cinema program selection of {cinemaName}!'
</i18n>
