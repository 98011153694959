<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationOrderOverviewCustomerCard :order :purchase-data />

    <p class="font-bold" v-text="ct('THANKYOU_H3', null, t('haveFunText'))" />

    <NuxtLink
      class="flex flex-row items-center underline"
      :to="
        localeRoute({
          name: ROUTE_NAMES.CINEMA_PROGRAM,
          params: {
            citySlug: order.cinema!.city.urlSlug,
            cinemaSlug: order.cinema!.urlSlug,
          },
        })
      "
    >
      <InlineSvg
        name="chevron-left"
        class="size-10 fill-black dark:fill-white"
      />
      <span
        v-text="t('backToCinemaProgram', { cinemaName: order.cinema!.name })"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'
import { ROUTE_NAMES } from '@portal/constants/routes'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()
const localeRoute = useLocaleRoute()

defineOptions({
  name: 'OrderConfirmationTypeCardCharging',
})
</script>

<i18n>
de:
  haveFunText: "Viel Spaß beim nächsten Kinobesuch."
  backToCinemaProgram: "Zurück zur Programmauswahl vom {cinemaName}!"
es:
  haveFunText: "¡Disfruta de tu próxima visita al cine!"
  backToCinemaProgram: "Volver a la selección de programas de {cinemaName}!"
en:
  haveFunText: "Have fun at your next cinema visit!"
  backToCinemaProgram: "Back to the cinema program selection of {cinemaName}!"
</i18n>
