import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18 17.272v2.445a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75v-11a.75.75 0 0 1 .75-.75h2.938l.395-3.763a.75.75 0 0 1 .825-.667l14.42 1.516a.75.75 0 0 1 .668.824l-1.15 10.94a.75.75 0 0 1-.824.667zm-14.5-.748v2.443h13v-3.825l-4.114 2.468a.75.75 0 0 1-.802-.019l-5.488-3.659-2.566 2.566zm13-3.131V9.467h-13v4.94l1.97-1.97a.75.75 0 0 1 .946-.094l5.605 3.737zM12 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2M7.196 7.967H17.25a.75.75 0 0 1 .75.75v7.046l1.433.151.993-9.448L7.497 5.107z" }, null, -1)
  ])))
}
export default { render: render }