import type { RouteLocationRaw } from 'vue-router'
import { MESSAGE_SEVERITY, MESSAGE_TYPE } from '@base/constants/message'
import type { Cart, CartType, Cinema } from '#gql/default'

export default async function useCart({
  onReset,
}: { onReset?: () => void } = {}) {
  const cart = useState<Cart | null>('cart', () => null)
  const cartId = useState<string | undefined>('cartId', () => undefined)
  const { add: addMessage } = useMessage()

  const route = useRoute()
  const router = useRouter()
  const localeRoute = useLocaleRoute()

  const pending = ref(false)
  const expiresAt = computed(() => cart.value?.expiresAt ?? null)
  const availablePaymentMethods = computed(
    () => cart.value?.availablePaymentMethods ?? []
  )

  useCountdown({
    expiresAt,
    onExpired: resetCart,
  })

  function displayError({
    message,
    type = MESSAGE_TYPE.TOAST,
  }: {
    message: string
    type?: MessageType
  }) {
    addMessage({
      message,
      severity: MESSAGE_SEVERITY.ERROR,
      type,
    })
  }

  function updateQueryParam() {
    if (cartId.value) {
      router.push({ query: { ...route.query, cartId: cartId.value } })
    } else {
      const query = { ...route.query }
      delete query.cartId
      router.push({ query })
    }
  }

  async function fetchCart(cartId?: string) {
    if (cart.value || !cartId) {
      return cart.value
    }

    const { data } = await useAsyncGql('FetchCart', {
      cartId,
    })

    if (!data.value?.cart) {
      resetCart()
      return
    }

    cart.value = data.value.cart as Cart
  }

  async function setEmailContact({ email }: { email: string }) {
    const result = await GqlCartSetEmailContact({
      cartId: cart.value!.id,
      email,
    })

    if (!result?.cartSetEmailContact) {
      throw new Error('Failed to set email contact')
    }

    cart.value = result.cartSetEmailContact as Cart
    return result.cartSetEmailContact.contact.email
  }

  function resetCart() {
    cart.value = null
    cartId.value = undefined
    updateQueryParam()
    onReset?.()
  }

  async function checkoutCart() {
    pending.value = true
    const result = await GqlCartCheckout({
      cartId: cart.value!.id,
    })

    if (!result?.cartCheckout) {
      pending.value = false
      throw new Error('Failed to checkout cart')
    }

    router.push(
      localeRoute({
        name: ROUTE_NAMES.ORDER,
        params: {
          orderNumber: result.cartCheckout.orderNumber,
          securityKey: result.cartCheckout.securityKey,
        },
      }) as RouteLocationRaw
    )
  }

  async function ensureCart({
    cinema,
    type,
  }: {
    cinema?: Cinema
    type: CartType
  }): Promise<Cart> {
    if (!cart.value || !cartId.value) {
      const result = await GqlCartCreate({
        cinemaId: cinema?.id,
        type,
      })

      if (!result?.cartCreate) {
        throw new Error('Failed to create cart')
      }

      cartId.value = result.cartCreate.id
      cart.value = result.cartCreate as Cart
      updateQueryParam()
    }

    return cart.value
  }

  if (!cart.value && route.query.cartId) {
    await fetchCart(route.query.cartId as string)
  }

  return {
    cart,
    pending,
    ensureCart,
    checkoutCart,
    setEmailContact,
    fetchCart,
    expiresAt,
    availablePaymentMethods,
    displayError,
  }
}
