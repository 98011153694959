import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 11.5c-1.52 0-2.88-2.07-2.88-3.69S10.41 5 12 5s2.88 1.26 2.88 2.81c0 1.63-1.35 3.69-2.88 3.69" }, null, -1),
    _createElementVNode("path", { d: "M12 5.75c1.17 0 2.12.92 2.12 2.06 0 1.35-1.15 2.94-2.12 2.94s-2.12-1.6-2.12-2.94c0-1.13.95-2.06 2.12-2.06m0-1.5c-2 0-3.62 1.59-3.62 3.56S10 12.25 12 12.25s3.62-2.48 3.62-4.44S14 4.25 12 4.25M18.75 20c.69 0 1.15-.67.95-1.33A6.67 6.67 0 0 0 13.33 14h-2.64c-2.99 0-5.52 1.96-6.37 4.67-.21.66.26 1.33.95 1.33h13.49Z" }, null, -1)
  ])))
}
export default { render: render }