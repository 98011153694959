import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2.215 2h5.827l5.053 7.353L19.421 2h1.726l-7.286 8.469L21.785 22h-5.827l-5.336-7.765L3.942 22H2.215l7.64-8.881zm2.349 1.3L16.77 20.759h2.652L7.215 3.3z" }, null, -1)
  ])))
}
export default { render: render }