import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  style: {"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linejoin":"round","stroke-miterlimit":"2"},
  viewBox: "0 0 160 160"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M0 80h160v80H0z",
      style: {"fill":"#fff"},
      transform: "matrix(1 0 0 2 0 -160)"
    }, null, -1),
    _createElementVNode("path", { d: "M23.396 87.275q6.647 0 10.212 3.271 3.567 3.27 3.567 9.063t-3.462 9.186q-3.462 3.392-10.595 3.392h-8.524V87.275zm.279 20.006q3.826 0 5.88-1.931 2.052-1.93 2.053-5.601t-2.053-5.654q-2.053-1.984-6.298-1.984h-3.096v15.17zm18.764-20.006h5.567v24.912h-5.567zm30.073 0v4.975H60.125v5.115h11.134v4.732H60.125v5.184h12.77v4.906H54.558V87.275zm23.218 0h5.567v24.912H95.73L83.865 96.565v15.622h-5.567V87.275h5.219l12.213 16.04zm19.336 4.314q-1.356 0-2.174.661-.819.661-.818 1.74 0 1.078.992 1.722.991.645 4.54 1.531 3.55.888 5.497 2.627 1.949 1.74 1.949 5.08t-2.505 5.428-6.611 2.088q-5.915 0-10.647-4.384l3.306-4.036q4.035 3.513 7.445 3.514 1.531 0 2.401-.661t.87-1.792-.922-1.775q-.922-.643-3.671-1.339-4.349-1.044-6.35-2.697t-2-5.201q0-3.549 2.54-5.463t6.367-1.914q2.505 0 4.993.853 2.487.852 4.332 2.418l-2.819 4.071q-3.235-2.47-6.715-2.471m25.629.488v20.11h-5.567v-20.11h-7.029v-4.802h19.659v4.802zM160 0v80H0V0zM61.055 31.986h-9.343v41.526h9.343zm53.296 15.811 11.36 16.38 11.361-16.38v25.715h9.344V31.986h-9.937l-10.768 16.723-10.767-16.723h-9.937v41.526h9.344zM69.7 73.512h29.848v-8.528H79.044V31.986H69.7zm-55.889 0h9.344v-15.9h19.467v-8.528H23.155v-8.57h22.336v-8.528h-31.68z" }, null, -1)
  ])))
}
export default { render: render }