import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "19.8",
      height: "1.6",
      x: "2",
      y: "11",
      rx: ".8",
      transform: "rotate(-45 12 12)"
    }, null, -1),
    _createElementVNode("rect", {
      width: "1.6",
      height: "19.8",
      x: "11",
      y: "2",
      rx: ".8",
      transform: "rotate(-45 12 12)"
    }, null, -1)
  ])))
}
export default { render: render }