import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-6.689 -6.689 996.278 236.338"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M422.65 79.44v139.47H365.1V102.28c0-28.74-21.39-44.25-41.51-44.25-17.43 0-31.05 8.33-44.13 27.02v133.87h-57.55V115.99c-5.81 2.01-11.78 3.7-17.65 5.01-3.08 59.36-45.04 101.68-102.04 101.68C42.99 222.68 0 176.98 0 114.02V4.82h57.56v109.2c0 23.57 11.7 51.1 44.67 51.1 30.33 0 42.66-23.3 44.43-45.39-23.91-10.06-38.71-34.3-38.71-66.49 0-33.34 24.86-52.95 48.84-52.95 30.68 0 47.66 30.56 47.5 60.93 0 0 8.51-3.05 17.62-8.22V4.81h57.55v18.37C299.15 7.16 317.33 0 338.31 0c23.18 0 44.55 8.11 60.16 22.84 15.6 14.72 24.18 34.82 24.18 56.6m22.95-23.88h97.44l-102.67 122-.08.09v41.26h182.44v-50.66H519.48L622.65 44.62l.08-.09V4.82H445.6Zm363.6-25.61c19.3 19.58 29.5 47.36 29.5 80.34v19.86H684.08c3.99 26.41 22.98 42.16 50.86 42.16 23.17 0 43.21-9.32 52.29-24.31l.15-.26.27.12 45.12 20.32-.16.32c-18.03 34.61-53.92 54.46-98.49 54.46-64 0-107-44.63-107-111.06 0-31.32 11.41-59.88 32.13-80.43C679.16 11.72 705.75.84 734.13.84c30.35.01 56.3 10.07 75.07 29.11m-26.54 55.21c-4.73-22.1-22.84-35.27-48.53-35.27-23.44 0-41.46 13.17-48.28 35.27zM982.9 3.25c-7.63-2.4-16.31-2.4-16.31-2.4-18.88 0-37.09 7.29-52.7 21.08V4.82h-57.55v214.1h57.55V86.13c11.35-21.54 28.77-33.4 49.05-33.4 0 0 9.12.08 14.8 1.75z",
      class: "st4",
      style: {"fill":"#fc1154","fill-opacity":"1"}
    }, null, -1)
  ])))
}
export default { render: render }