import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18 17.272v2.445a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75v-11a.75.75 0 0 1 .75-.75h2.938l.395-3.763a.75.75 0 0 1 .825-.667l14.42 1.516a.75.75 0 0 1 .668.824l-1.15 10.94a.75.75 0 0 1-.824.667zM3.5 18.967h13v-9.5h-13zm8.595-4.911a.2.2 0 0 1 0 .334l-3.081 2.044a.199.199 0 0 1-.31-.166v-4.09a.2.2 0 0 1 .31-.166zM7.196 7.967H17.25a.75.75 0 0 1 .75.75v7.046l1.433.151.993-9.448L7.497 5.107z" }, null, -1)
  ])))
}
export default { render: render }