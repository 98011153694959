import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 2c5.519 0 10 4.481 10 10s-4.481 10-10 10S2 17.519 2 12 6.481 2 12 2m0 1.5c-4.691 0-8.5 3.809-8.5 8.5s3.809 8.5 8.5 8.5 8.5-3.809 8.5-8.5-3.809-8.5-8.5-8.5m3.793 8.888-5.538 3.778a.49.49 0 0 1-.497.03.47.47 0 0 1-.258-.418V8.222c0-.176.099-.337.258-.418a.49.49 0 0 1 .497.03l5.538 3.778a.468.468 0 0 1 0 .776" }, null, -1)
  ])))
}
export default { render: render }