import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.568 3H3.73a.73.73 0 0 0-.73.73v16.54c0 .403.327.73.73.73h16.54a.73.73 0 0 0 .73-.73v-5.838a.73.73 0 0 0-1.459 0v5.109H4.459V4.459h5.109a.73.73 0 0 0 0-1.459m-1.744 8.58a.75.75 0 0 0-.219.509l-.105 3.64a.75.75 0 0 0 .771.771l3.64-.105a.75.75 0 0 0 .509-.219l9.36-9.36a.75.75 0 0 0 0-1.061L18.245 2.22a.75.75 0 0 0-1.061 0zm1.271.85 8.619-8.619 2.475 2.475-8.619 8.619-2.548.073z" }, null, -1)
  ])))
}
export default { render: render }