import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m6.273 19.015-1.22 2.227a.53.53 0 0 0 .72.719L8 20.741l2.227 1.22a.53.53 0 0 0 .72-.719l-1.22-2.227 1.22-2.228a.53.53 0 0 0-.72-.719L8 17.288l-2.227-1.22a.53.53 0 0 0-.72.719zm8.626-3.95L13.2 18.168a.562.562 0 0 0 .763.763l3.102-1.7 3.103 1.7a.56.56 0 0 0 .763-.763l-1.7-3.103 1.7-3.102a.562.562 0 0 0-.763-.763l-3.103 1.699-3.102-1.699a.564.564 0 0 0-.668.095.564.564 0 0 0-.095.668zM6.547 6.547 2.36 7.77a.5.5 0 0 0 0 .96l4.187 1.223L7.77 14.14a.5.5 0 0 0 .96 0l1.223-4.187L14.14 8.73a.5.5 0 0 0 0-.96L9.953 6.547 8.73 2.36a.5.5 0 0 0-.96 0z" }, null, -1)
  ])))
}
export default { render: render }