import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 6.75c1.17 0 2.12.92 2.12 2.06 0 1.35-1.15 2.94-2.12 2.94s-2.12-1.6-2.12-2.94c0-1.13.95-2.06 2.12-2.06m0-1.5c-2 0-3.62 1.59-3.62 3.56S10 13.25 12 13.25s3.62-2.48 3.62-4.44S14 5.25 12 5.25" }, null, -1),
    _createElementVNode("path", { d: "M12 3.5c4.69 0 8.5 3.81 8.5 8.5s-3.81 8.5-8.5 8.5-8.5-3.81-8.5-8.5S7.31 3.5 12 3.5M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2" }, null, -1),
    _createElementVNode("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18.5c-4.69 0-8.5-3.81-8.5-8.5S7.31 3.5 12 3.5s8.5 3.81 8.5 8.5-3.81 8.5-8.5 8.5" }, null, -1),
    _createElementVNode("path", { d: "M12 14.5a9.98 9.98 0 0 0-7.8 3.75c.32.4.67.76 1.04 1.11C6.79 17.32 9.24 16 12 16s5.2 1.32 6.76 3.36c.37-.34.72-.71 1.04-1.11A9.98 9.98 0 0 0 12 14.5" }, null, -1)
  ])))
}
export default { render: render }