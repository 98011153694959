import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M22 6.562v10.876a4.52 4.52 0 0 1-4.516 4.516H6.516A4.52 4.52 0 0 1 2 17.438V6.562a4.52 4.52 0 0 1 4.516-4.516h10.968A4.52 4.52 0 0 1 22 6.562m-16.228 3.2v8.388h2.741V9.762zM8.513 7.22a1.37 1.37 0 1 0-2.74 0 1.37 1.37 0 0 0 2.74 0m1.494 2.542v8.388h2.632v-4.135c0-1.047.211-2.059 1.583-2.059 1.351 0 1.37 1.197 1.37 2.127l.001 4.067h2.635v-4.567c0-2.16-.494-3.821-3.162-3.821-1.283 0-2.144.665-2.496 1.296h-.036V9.762z" }, null, -1)
  ])))
}
export default { render: render }