import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M22 7.6v8.8c0 3.091-2.509 5.6-5.6 5.6H7.6A5.6 5.6 0 0 1 2 16.4V7.6C2 4.509 4.509 2 7.6 2h8.8C19.491 2 22 4.509 22 7.6m-1.5 0c0-2.263-1.837-4.1-4.1-4.1H7.6a4.1 4.1 0 0 0-4.1 4.1v8.8c0 2.263 1.837 4.1 4.1 4.1h8.8c2.263 0 4.1-1.837 4.1-4.1zm-3.25-2.1a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5M12 7.5c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5M12 9a3.5 3.5 0 0 0-3.5 3.5c0 1.932 1.568 3.5 3.5 3.5s3.5-1.568 3.5-3.5S13.932 9 12 9" }, null, -1)
  ])))
}
export default { render: render }