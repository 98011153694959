<template>
  <InlineSvg
    v-if="locale === 'es'"
    :name="SVG_ICON.APPLE_WALLET_ADD_ES"
    class="aspect-auto w-36 print:hidden"
  />
  <InlineSvg
    v-else
    :name="SVG_ICON.APPLE_WALLET_ADD_DE"
    class="aspect-auto w-36 print:hidden"
  />
</template>

<script setup lang="ts">
import { SVG_ICON } from '@assets/constants/inline-svg'

const { locale } = useI18n()

defineOptions({
  name: 'IconAppleWallet',
})
</script>
