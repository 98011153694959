import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "size-20 fill-black text-black",
  viewBox: "0 0 200 200"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<radialGradient id=\"a\" cx=\".66\" cy=\".313\" fx=\".66\" fy=\".313\" gradientTransform=\"scale(1.5)\"><stop offset=\"0\" stop-opacity=\"0\"></stop><stop offset=\".3\" stop-opacity=\".3\"></stop><stop offset=\".6\" stop-opacity=\".6\"></stop><stop offset=\".8\" stop-opacity=\".9\"></stop><stop offset=\"1\"></stop></radialGradient><circle cx=\"100\" cy=\"100\" r=\"70\" fill=\"none\" stroke=\"url(#a)\" stroke-dasharray=\"200 1000\" stroke-linecap=\"round\" stroke-width=\"15\" transform-origin=\"center\"></circle>", 2)
  ])))
}
export default { render: render }