import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 2571 1250"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M856.098 319.667c0 30.183-24.504 54.688-54.687 54.688H158.692c-30.183 0-54.688-24.505-54.688-54.688 0-30.182 24.505-54.687 54.688-54.687h642.719c30.183 0 54.687 24.505 54.687 54.687m0 609.191c0 30.182-24.504 54.687-54.687 54.687H54.688C24.505 983.545 0 959.04 0 928.858c0-30.183 24.505-54.688 54.688-54.688h746.723c30.183 0 54.687 24.505 54.687 54.688m0-304.596c0 30.183-24.504 54.688-54.687 54.688H310.637c-30.182 0-54.687-24.505-54.687-54.688 0-30.182 24.505-54.687 54.687-54.687h490.774c30.183 0 54.687 24.505 54.687 54.687m1714.28-436.409v874.294c0 103.679-84.174 187.853-187.853 187.853h-1215.2c-103.678 0-187.853-84.174-187.853-187.853V187.853C979.472 84.174 1063.647 0 1167.325 0h1215.2c103.679 0 187.853 84.174 187.853 187.853m-121.858-51.441c-15.321-19.621-39.195-32.245-65.995-32.245h-1215.2c-26.827 0-50.724 12.651-66.043 32.308l673.637 426.155 673.604-426.218ZM1083.64 248.574v813.573c0 46.188 37.499 83.686 83.686 83.686h1215.2c46.188 0 83.687-37.498 83.687-83.686V248.486l-663.445 419.789a52.09 52.09 0 0 1-55.694.003l-663.437-419.704Z" }, null, -1)
  ])))
}
export default { render: render }