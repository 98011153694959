import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14.321 22c-3.008 0-5.249-1.547-5.249-5.25v-5.928H6.338V7.611C9.347 6.83 10.604 4.242 10.75 2h3.123v5.09h3.643v3.732h-3.643v5.162c0 1.548.78 2.082 2.024 2.082h1.765V22z" }, null, -1)
  ])))
}
export default { render: render }