<template>
  <div v-if="showAgeRating" class="mt-11 flex flex-col">
    <PageHeading
      :tag="PAGE_HEADING_TAG.H3"
      :title="
        t('title', { age: order?.show?.movie?.contentRating?.minimumAge })
      "
    />
    <Divider />

    <span
      class="mt-2 print:!text-black print:dark:!text-black"
      v-text="t('entry')"
    />
    <a
      class="hover:text-primary underline print:hidden"
      href="https://www.spio-fsk.de/?seitid=508&tid=72"
      :target="LINK_TARGET.BLANK"
    >
      {{ t('linkLabel') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const showAgeRating = computed(() => {
  return (order?.show?.movie?.contentRating?.minimumAge ?? 0) > 0
})

defineOptions({
  name: 'OrderConfirmationAgeRating',
})
</script>

<i18n>
de:
  title: "Bitte die FSK {age} Altersfreigabe beachten."
  entry: "Einlass nur mit gültigen Altersnachweis. Bei Missachtung besteht kein Anspruch auf Stornierung oder Rückzahlung. Bitte bei den Abendvorstellungen die Regelungen zum Jugendschutz beachten."
  linkLabel: "FSK-Regelung"
es:
  title: "Por favor, tenga en cuenta la clasificación por edades FSK {age}."
  entry: "Solo se permite la entrada con una identificación válida. En caso de incumplimiento, no se podrá cancelar ni reembolsar la entrada. Por favor, tenga en cuenta las regulaciones"
  linkLabel: "Regulación FSK"
en:
  title: "Please note the FSK {age} age rating."
  entry: "Only entry with a valid identification. In case of non-compliance, no cancellation or refund is possible. Please note the regulations"
  linkLabel: "FSK regulation"
</i18n>
