import { useRouteQuery } from '@vueuse/router'
import {
  VOUCHER_SHOP_QUERY_PARAM,
  VOUCHER_SHOP_PANEL,
} from '@voucher-shop/constants/voucher-shop'
import type { Panel } from '@base/types/panel-manager'
import type {
  VoucherShopData,
  VoucherShopStepKey,
  CustomisedVoucherProduct,
  VoucherProductCustomisation,
} from '@base/types/voucher-shop'
import type {
  Cinema,
  VoucherProduct,
  VoucherProductType,
  CartVoucherProduct,
} from '#gql/default'

export default async function useVoucherShop() {
  const { STEP, VOUCHER_ID, CART_VOUCHER_ID } = VOUCHER_SHOP_QUERY_PARAM
  const { CONFIGURE, SELECT } = VOUCHER_SHOP_PANEL

  const route = useRoute()
  const router = useRouter()
  const step = useRouteQuery<VoucherShopStepKey | undefined>(STEP)
  const voucherId = useRouteQuery<string | undefined>(VOUCHER_ID)
  const cartVoucherId = useRouteQuery<string | undefined>(CART_VOUCHER_ID)

  const voucherShopData = useState<VoucherShopData>('voucherShopData', () => ({
    voucherProducts: [],
    voucherProductTypes: [],
    isInitalised: false,
    panels: [],
  }))

  const fetchVariables = ref()
  const { data, execute } = await useAsyncGql(
    'FetchVoucherProducts',
    fetchVariables,
    { immediate: false }
  )

  /**
   * Initialise the voucher shop with the specified cinema and voucher product types and current panel.
   * If the voucher products are not set, the function will fetch the voucher products first.
   */
  async function initialise({
    cinema,
    voucherProductTypes,
    panels,
  }: {
    cinema?: Cinema
    voucherProductTypes?: VoucherProductType[]
    panels: Panel[]
  }) {
    if (!voucherShopData.value.voucherProducts.length) {
      fetchVariables.value = {
        cinemaId: cinema?.id,
        types: voucherProductTypes,
      }
      await execute()

      voucherShopData.value = {
        ...voucherShopData.value,
        voucherProducts: data.value?.voucherProducts as VoucherProduct[],
        cinema,
        voucherProductTypes,
        isInitalised: true,
        panels,
      }
    }

    if (
      !Object.values(VOUCHER_SHOP_PANEL).includes(
        step.value as VoucherShopStepKey
      )
    ) {
      setInitialPanel()
      return
    }

    if (
      step.value !== CONFIGURE ||
      (!voucherId.value && !cartVoucherId.value)
    ) {
      setPanel(step.value as VoucherShopStepKey)
      return
    }

    const { getCartVoucher } = await useVoucherCart({ cinema })
    const cartVoucher = cartVoucherId.value
      ? await getCartVoucher(cartVoucherId.value)
      : undefined
    const voucher = voucherId.value ? getVoucher(voucherId.value) : undefined

    if (!voucher && !cartVoucher) {
      setInitialPanel()
      return
    }

    configure({ voucher, cartVoucher })

    setPanel(CONFIGURE, {
      voucherId: voucherId.value,
      cartVoucherId: cartVoucherId.value,
    })
  }

  /**
   * Configure the voucher product with the specified voucher or cart voucher.
   * If both voucher and cartVoucher are provided, the cartVoucher will be used.
   * The function will update the voucher product data with the selected voucher or cart voucher.
   */
  function configure({
    voucher,
    cartVoucher,
  }: {
    voucher?: VoucherProduct
    cartVoucher?: CartVoucherProduct
  }) {
    const selectedVoucher =
      (cartVoucher?.voucherProduct as VoucherProduct) ?? voucher

    voucherShopData.value.voucherProduct = {
      ...selectedVoucher,
      quantity: cartVoucher?.quantity ?? 1,
      selectedAmount: cartVoucher?.selectedAmount,
      voucherDesignId: cartVoucher?.voucherDesign?.id,
      cartVoucherId: cartVoucher?.id,
      customisations:
        (cartVoucher?.customisations as VoucherProductCustomisation[]) ?? [],
    }
  }

  /**
   * Customise the voucher product at the specified index.
   * If the voucher product is not set, the function will return.
   * The function will add new customisations if the index is greater than the current number of customisations.
   * The function will update the existing customisation if the index is within the current range.
   */
  function customise(index: number, data: VoucherProductCustomisation) {
    if (!voucherShopData.value.voucherProduct) {
      return
    }
    while (
      voucherShopData.value.voucherProduct.customisations.length <= index
    ) {
      voucherShopData.value.voucherProduct.customisations.push({})
    }
    voucherShopData.value.voucherProduct.customisations[index] = {
      ...voucherShopData.value.voucherProduct.customisations[index],
      ...data,
    }
  }

  /**
   * Get the voucher product with the specified ID.
   * @param voucherId - The ID of the voucher product to retrieve.
   * @returns The voucher product with the specified ID, or undefined if not found.
   */
  function getVoucher(voucherId: string) {
    return voucherShopData.value.voucherProducts.find(
      ({ id }) => voucherId === id
    )
  }

  /**
   * Set the initial panel based on the number of voucher products.
   * If there is only one voucher product, it will be configured automatically.
   * Otherwise, the SELECT panel will be set.
   */
  function setInitialPanel() {
    if (voucherShopData.value.voucherProducts.length === 1) {
      const voucher = voucherShopData.value.voucherProducts[0]
      configure({ voucher })

      setPanel(CONFIGURE, {
        voucherId: voucher.id,
      })
    } else {
      setPanel(SELECT)
    }
  }

  /**
   * Set the panel based on the next panel key.
   * If the next panel is not valid, the initial panel will be set.
   * The panel will be set with the voucher ID or cart voucher ID if the next panel is CONFIGURE.
   */
  function setPanel(
    nextPanel?: VoucherShopStepKey,
    options?: {
      voucherId?: string
      cartVoucherId?: string
    }
  ) {
    if (!nextPanel || !Object.values(VOUCHER_SHOP_PANEL).includes(nextPanel)) {
      setInitialPanel()
      return
    }

    const query: Record<string, string | undefined> = {
      ...route.query,
      [STEP]: nextPanel,
      [VOUCHER_ID]: nextPanel === CONFIGURE ? options?.voucherId : undefined,
      [CART_VOUCHER_ID]:
        nextPanel === CONFIGURE ? options?.cartVoucherId : undefined,
    }

    router.push({
      query,
    })
  }

  return {
    step,
    panels: computed<Panel[]>(() => voucherShopData.value.panels),
    activePanel: computed<Panel | undefined>(() => {
      const panel = voucherShopData.value.panels.find(
        (panel) => panel.key === step.value
      )
      if (!panel) return undefined

      if (panel?.key === CONFIGURE) {
        return {
          ...panel,
          title: voucherShopData.value.voucherProduct?.name,
        }
      }

      if (!panel || !voucherShopData.value.isInitalised) {
        return undefined
      }

      return panel as Panel
    }),
    initialise,
    configure,
    customise,
    setPanel,
    setInitialPanel,
    voucherProduct: computed<CustomisedVoucherProduct | undefined>(
      () => voucherShopData.value.voucherProduct
    ),
    voucherProducts: computed<VoucherProduct[]>(
      () => voucherShopData.value.voucherProducts
    ),
    cinema: computed<Cinema | undefined>(() => voucherShopData.value.cinema),
    isInitalised: computed<boolean>(() => voucherShopData.value.isInitalised),
  }
}
