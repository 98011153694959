import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.245 13.235a3.5 3.5 0 0 1-2.244-3.206L3 10V4.75a.75.75 0 0 1 1.5 0V9a.625.625 0 0 0 1.25 0V4.75a.75.75 0 0 1 1.5 0V9A.625.625 0 0 0 8.5 9V4.75a.75.75 0 0 1 1.5 0V10l-.001.029a3.5 3.5 0 0 1-2.244 3.206v6.51a1.256 1.256 0 0 1-2.51 0zM15.26 9h-3.49a.75.75 0 0 0 0 1.5h.268l.777 9.809a.75.75 0 0 0 .747.691h4.876a.75.75 0 0 0 .747-.691l.777-9.809h.288a.75.75 0 0 0 0-1.5h-3.49V6.401l2.657-1.778a.75.75 0 1 0-.834-1.246l-2.99 2A.75.75 0 0 0 15.26 6z" }, null, -1)
  ])))
}
export default { render: render }