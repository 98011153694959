import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M20 17.25H8.43l-.19-1.58 12.12-.95c.82-.06 1.49-.7 1.6-1.52l.78-5.97c.06-.5-.09-1-.42-1.38s-.81-.59-1.31-.59H7.02l-.11-.97a2.77 2.77 0 0 0-2.06-2.35l-2.66-.67c-.41-.1-.81.14-.91.55-.1.4.14.81.55.91l2.66.67c.5.13.88.55.94 1.07l1.53 13c-.99.41-1.7 1.4-1.7 2.54 0 1.52 1.23 2.75 2.75 2.75s2.75-1.23 2.75-2.75c0-.45-.12-.87-.31-1.25h7.13c-.19.38-.31.8-.31 1.25 0 1.52 1.23 2.75 2.75 2.75s2.75-1.23 2.75-2.75-1.23-2.75-2.75-2.75zm1-10.5c.1 0 .16.05.19.08s.07.1.06.2L20.47 13c-.02.12-.11.21-.23.22l-12.17.95-.87-7.42zM9.25 20a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M20 21.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5M17.82 11V9c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .41-.34.75-.75.75s-.75-.34-.75-.75m-4 0V9c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .41-.34.75-.75.75s-.75-.34-.75-.75m-4 0V9c0-.41.34-.75.75-.75s.75.34.75.75v2c0 .41-.34.75-.75.75s-.75-.34-.75-.75" }, null, -1)
  ])))
}
export default { render: render }