import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 3.5c4.69 0 8.5 3.81 8.5 8.5s-3.81 8.5-8.5 8.5-8.5-3.81-8.5-8.5S7.31 3.5 12 3.5M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2" }, null, -1),
    _createElementVNode("path", { d: "M12 3.5c.64 0 2.5 2.92 2.5 8.5s-1.86 8.5-2.5 8.5-2.5-2.92-2.5-8.5 1.86-8.5 2.5-8.5M12 2C9.79 2 8 6.48 8 12s1.79 10 4 10 4-4.48 4-10-1.79-10-4-10" }, null, -1),
    _createElementVNode("path", { d: "M21 8H3v1.5h18zM21 14.5H3V16h18z" }, null, -1)
  ])))
}
export default { render: render }