import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m11 16.025 1-.76 2.81 2.138a.47.47 0 0 0 .573 0 .525.525 0 0 0 .177-.571l-1.073-3.459 2.81-2.138a.52.52 0 0 0 .177-.571.49.49 0 0 0-.464-.352h-3.473l-1.074-3.459A.49.49 0 0 0 12 6.5a.49.49 0 0 0-.463.353L11 8.582V2.049S11.663 2 12 2c5.519 0 10 4.481 10 10s-4.481 10-10 10c-.337 0-1-.049-1-.049z" }, null, -1),
    _createElementVNode("path", { d: "M13 21.951S12.337 22 12 22C6.481 22 2 17.519 2 12S6.481 2 12 2c.337 0 1 .049 1 .049v6.533l-.537-1.729A.49.49 0 0 0 12 6.5a.49.49 0 0 0-.463.353l-1.074 3.459H6.99a.49.49 0 0 0-.464.352.52.52 0 0 0 .177.571l2.81 2.138-1.073 3.459a.525.525 0 0 0 .177.571.47.47 0 0 0 .573 0L12 15.265l1 .76z" }, null, -1)
  ])))
}
export default { render: render }