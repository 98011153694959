import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M21.005 21.342A2.85 2.85 0 0 0 22 19.193V16.18a2 2 0 0 0-1.41-1.91l-3.477-1.073a2 2 0 0 0-1.826.339l-1.388 1.09a18.4 18.4 0 0 1-4.525-4.524l1.091-1.388a2 2 0 0 0 .34-1.826L9.731 3.41A2 2 0 0 0 7.82 2H4.807a2.85 2.85 0 0 0-2.149.995c-.444.52-.696 1.182-.653 1.864a18.25 18.25 0 0 0 5.33 11.806 18.25 18.25 0 0 0 11.806 5.33c.682.043 1.344-.209 1.864-.653M8.195 9.173a1.5 1.5 0 0 0-.05 1.788c.656.935 1.4 1.826 2.234 2.66a20 20 0 0 0 2.66 2.233 1.5 1.5 0 0 0 1.788-.05l1.387-1.09a.5.5 0 0 1 .457-.085l3.476 1.073a.5.5 0 0 1 .353.478v3.009c-.003.39-.174.76-.47 1.013-.26.222-.547.312-.796.296a16.75 16.75 0 0 1-10.839-4.893 16.75 16.75 0 0 1-4.893-10.84c-.016-.248.074-.536.296-.794A1.35 1.35 0 0 1 4.81 3.5h3.01a.5.5 0 0 1 .477.353l1.073 3.476a.5.5 0 0 1-.085.457z" }, null, -1)
  ])))
}
export default { render: render }