import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18.115 6.009A4.8 4.8 0 0 1 15.937 2h-3.439l-.005 13.78a2.89 2.89 0 0 1-2.885 2.781 2.891 2.891 0 0 1-2.891-2.89 2.894 2.894 0 0 1 2.891-2.89c.297 0 .583.049.852.133v-3.51a6 6 0 0 0-.852-.061 6.335 6.335 0 0 0-6.329 6.328 6.33 6.33 0 0 0 2.702 5.182A6.3 6.3 0 0 0 9.608 22a6.336 6.336 0 0 0 6.329-6.329V8.684a8.2 8.2 0 0 0 4.784 1.538V6.783a4.76 4.76 0 0 1-2.606-.774" }, null, -1)
  ])))
}
export default { render: render }