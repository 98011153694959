import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.53 14.53 12 10.061l4.47 4.469a.749.749 0 1 0 1.06-1.06l-5-5a.75.75 0 0 0-1.06 0l-5 5a.749.749 0 1 0 1.06 1.06" }, null, -1)
  ])))
}
export default { render: render }