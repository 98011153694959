import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.625 7a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 0 1.5zm-3.75 3.75a.75.75 0 0 1 0-1.5h4.75a.75.75 0 0 1 0 1.5zm.5 3.75a.75.75 0 0 1 0-1.5h2.25a.75.75 0 0 1 0 1.5zm3.099 1.433 7.723-13.376a1 1 0 0 1 1.368-.367l2.438 1.407c.092.054.129.17.084.267a1.5 1.5 0 0 0 .631 1.882 1.503 1.503 0 0 0 1.952-.398.2.2 0 0 1 .259-.057c.568.324 2.445 1.407 2.445 1.407.479.277.643.89.367 1.369l-7.723 13.376c-.276.479-.89.643-1.369.367 0 0-1.877-1.084-2.441-1.414a.197.197 0 0 1-.08-.253 1.503 1.503 0 0 0-.632-1.889 1.5 1.5 0 0 0-1.945.395.21.21 0 0 1-.273.06l-2.437-1.407a1 1 0 0 1-.367-1.369m6.269-7.853-4.592 7.954a.25.25 0 0 0 .092.342l.999.577a3.005 3.005 0 0 1 4.509 2.603l.999.577a.25.25 0 0 0 .342-.092l4.592-7.953-1.084-.626a.751.751 0 1 1 .751-1.302l1.085.626 1.628-2.82a.25.25 0 0 0-.092-.342l-.999-.577a3.005 3.005 0 0 1-4.509-2.603l-.999-.577a.25.25 0 0 0-.343.092l-1.628 2.82 1.085.626a.752.752 0 0 1-.751 1.301zm5.148 2.105a.753.753 0 0 1-1.027.275l-1.301-.752a.75.75 0 1 1 .751-1.301l1.302.751a.753.753 0 0 1 .275 1.027" }, null, -1)
  ])))
}
export default { render: render }