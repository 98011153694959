import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"256\" cy=\"256\" r=\"256\" fill=\"#f0f0f0\"></circle><path fill=\"#0052b4\" d=\"M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178zm450.261 89.077c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005zm89.075-450.26c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075zm133.566 494.362c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075zm47.222-180.397 89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076z\"></path><g fill=\"#d80027\"><path d=\"M509.833 222.609H289.392V2.167A259 259 0 0 0 256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A259 259 0 0 0 0 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0 0 66.783 0V289.392h220.442A259 259 0 0 0 512 256c0-11.317-.744-22.461-2.167-33.391\"></path><path d=\"M322.783 322.784 437.019 437.02a257 257 0 0 0 15.048-16.435l-97.802-97.802h-31.482zm-133.566 0h-.002L74.98 437.019a257 257 0 0 0 16.435 15.048l97.802-97.804zm0-133.565v-.002L74.981 74.98a257 257 0 0 0-15.048 16.435l97.803 97.803zm133.566 0L437.02 74.981a256 256 0 0 0-16.435-15.047l-97.802 97.803z\"></path></g>", 3)
  ])))
}
export default { render: render }