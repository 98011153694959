import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.11 18.5c-.64 0-1.22-.44-1.4-1.06L4.05 5.13c-.03-.14-.2-.95.32-1.57.25-.29.73-.63 1.63-.55l.44.06c.86.11 1.58.69 1.89 1.52l2.88 7.84 4.51-1.06c1.57-.28 2.09-.21 2.76.05.89.34 1.42 1.03 1.49 1.94.06.65-.26 1.34-.5 1.81l-1.37 2.42c-.23.54-.75.88-1.33.88l-7.68.03Zm-3.36-14c-.16 0-.24.03-.26.05-.02.03-.02.13 0 .2L9.13 17l7.67-.04 1.41-2.52c.31-.6.33-.87.32-.96-.02-.28-.13-.5-.55-.67-.33-.13-.57-.22-1.94.02l-4.7 1.1c-.65.04-1.24-.34-1.48-.95L6.97 5.12a.86.86 0 0 0-.7-.56l-.41-.05h-.1Z" }, null, -1),
    _createElementVNode("rect", {
      width: "18",
      height: "1.5",
      x: "3",
      y: "20.5",
      rx: ".75",
      ry: ".75"
    }, null, -1)
  ])))
}
export default { render: render }