<template>
  <ImageWrapper
    :image="imageObject"
    :config="imageConfig"
    :classes
    :render-above-the-fold="false"
  >
    <template #default="{ classList }">
      <ImagePlaceholder
        :icon="SVG_ICON.GALLERY_IMAGE"
        :class="[...classList]"
      />
    </template>
  </ImageWrapper>
</template>

<script setup lang="ts">
import type { VoucherProduct } from '#gql/default'
import { SVG_ICON } from '@assets/constants/inline-svg'

interface Props {
  voucherProduct: VoucherProduct
}

const { voucherProduct } = defineProps<Props>()

const classes = computed(() => ({
  placeholder: 'w-full h-full',
  image: 'w-full h-auto',
  container: 'aspect-[4/3] overflow-hidden',
}))

const imageObject = getImage(voucherProduct, 'image', voucherProduct.name)
const config = useAppConfig()
const imageConfig = computed(() => config.images.voucher.card)

defineOptions({
  name: 'VoucherProductImage',
})
</script>
