import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m4.002 8.295 1.033 13.012a.764.764 0 0 0 .771.693h12.388c.404 0 .74-.302.771-.693l1.033-13.012a.76.76 0 0 0-.713-.806.767.767 0 0 0-.831.69l-.977 12.32H6.523l-.977-12.32a.767.767 0 0 0-.831-.69.76.76 0 0 0-.713.806M13.25 10v7a.75.75 0 0 0 1.5 0v-7a.75.75 0 0 0-1.5 0m-4 0v7a.75.75 0 0 0 1.5 0v-7a.75.75 0 0 0-1.5 0M20.232 5H3.772A.76.76 0 0 0 3 5.749c0 .414.345.751.771.751H20.23a.76.76 0 0 0 .772-.749.76.76 0 0 0-.77-.751" }, null, -1),
    _createElementVNode("path", { d: "M7.503 6H9l.007-.994A1.665 1.665 0 0 1 10.664 3.5h2.672c.868 0 1.581.665 1.658 1.512L15 6h1.497v-.986A3.165 3.165 0 0 0 13.336 2h-2.672a3.167 3.167 0 0 0-3.161 3.004z" }, null, -1)
  ])))
}
export default { render: render }