import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M3.96 20.682V5.037A3.037 3.037 0 0 1 6.997 2h10.006a3.037 3.037 0 0 1 3.037 3.037v15.645a1.316 1.316 0 0 1-1.955 1.153L12 18.47l-6.085 3.365a1.317 1.317 0 0 1-1.955-1.153m1.5-.309 5.814-3.215a1.5 1.5 0 0 1 1.452 0l5.814 3.215V5.037c0-.849-.688-1.537-1.537-1.537H6.997c-.849 0-1.537.688-1.537 1.537z" }, null, -1)
  ])))
}
export default { render: render }