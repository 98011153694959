import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  style: {"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linejoin":"round","stroke-miterlimit":"2"},
  viewBox: "0 0 1250 1250"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M0 0h1250v1250H0z",
      style: {"fill":"none"}
    }, null, -1),
    _createElementVNode("circle", {
      cx: "625",
      cy: "628.654",
      r: "585.886",
      style: {"fill-opacity":".3"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M625 0c344.947 0 625 280.053 625 625s-280.053 625-625 625S0 969.947 0 625 280.053 0 625 0m0 83.333C326.046 83.333 83.333 326.046 83.333 625S326.046 1166.667 625 1166.667 1166.667 923.954 1166.667 625 923.954 83.333 625 83.333",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M838.238 608.35a25 25 0 0 1 0 40.608l-276.7 198.771a25 25 0 0 1-39.585-20.304V429.883a25.001 25.001 0 0 1 39.585-20.304z",
      style: {"fill":"#fff"}
    }, null, -1)
  ])))
}
export default { render: render }