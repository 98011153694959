import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "h-6",
  viewBox: "0 0 583.91 113.05"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M316.56.02v33.15c5.84-2.84 12-4.26 18.63-4.42 22.58-.32 40.26 12.63 40.26 36.79v45.47h-12.32V65.54c-.16-16.26-12.47-25.58-27.95-25.58-6 0-12.63 1.26-18.63 3.47v67.59h-12.32V.02h12.32ZM456.74 66c-1.27-14.68-12-25.26-27.32-25.58-17.37.16-26.53 12.48-28.58 25.58zm-4.27 25.11 9.95 6c-8.05 10.89-19.74 15.95-33 15.95-27.16 0-40.9-21-40.74-41.68 0-20.68 14.05-41.69 40.58-41.84 25.42-.16 42.47 21.32 38.69 46.89h-67.26c.95 13.58 11.05 25.74 28.73 25.74 8.84 0 17.69-3.79 23.05-11.05M506.78 111.63c-16.42 1.73-25.26-6.32-25.26-23.84V0h12.47v87.79c0 9 2.37 13.74 12.79 12.95v10.9ZM571.59 100.11V44.05c-6-2.37-12.63-3.47-18.63-3.47-19.11 0-29.37 16.26-29.53 31.11-.16 14.69 10.42 30.79 29.53 30.79 6.16-.16 12.63-.63 18.63-2.37M583.91 0v108.63c-10.42 3.47-21 4.42-30.95 4.42-27 0-41.84-20.68-41.69-41.37 0-20.68 14.84-42.16 41.69-42.16 6.47-.16 12.79 1.42 18.63 4.26V0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#edce3b",
      d: "M21 .49v57.79l25.9-27.47h25.73L40.26 64.76l33.32 46.26H49.74L26.21 77.86 21 83.23v27.79H0V.49zM107.41 111.02H85.78V30.97h21.63zM85.78.65h21.47v21.31H85.78zM122.41 35.07c29.21-10.58 73.74-10.58 74.21 30v45.95h-21.47V65.23c.16-17.68-18-20.84-30.95-15.47v61.26h-21.79z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#edce3b",
      "fill-rule": "evenodd",
      d: "M250.29 29.13c23.12 0 41.89 18.77 41.89 41.89s-18.77 41.89-41.89 41.89-41.89-18.77-41.89-41.89 18.77-41.89 41.89-41.89m33.54 30.99c1.92 5.92-1.32 12.28-7.24 14.2s-12.28-1.32-14.2-7.24 1.32-12.28 7.24-14.21c5.92-1.92 12.28 1.32 14.2 7.24m-12.81 39.43c-5.03 3.66-12.09 2.54-15.74-2.49-3.66-5.03-2.54-12.09 2.49-15.74 5.03-3.66 12.09-2.54 15.74 2.49 3.66 5.03 2.54 12.09-2.49 15.75m-20.73-63.79c6.22 0 11.27 5.05 11.27 11.27s-5.05 11.27-11.27 11.27-11.27-5.05-11.27-11.27 5.05-11.27 11.27-11.27m-6.98 35.26c0-3.85 3.13-6.98 6.98-6.98s6.98 3.13 6.98 6.98-3.13 6.98-6.98 6.98-6.98-3.13-6.98-6.98m-13.74 28.53c-5.03-3.66-6.15-10.71-2.49-15.75 3.66-5.03 10.71-6.15 15.74-2.49s6.15 10.71 2.49 15.75c-3.66 5.03-10.71 6.15-15.74 2.49m-12.81-39.43c1.92-5.92 8.29-9.16 14.2-7.24 5.92 1.92 9.16 8.29 7.24 14.21s-8.29 9.16-14.2 7.24c-5.92-1.92-9.16-8.29-7.24-14.2"
    }, null, -1)
  ])))
}
export default { render: render }