import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M21.25 11.25H20A8 8 0 0 0 12.75 4V2.75a.75.75 0 0 0-1.5 0V4A8 8 0 0 0 4 11.25H2.75a.75.75 0 0 0 0 1.5H4A8 8 0 0 0 11.25 20v1.29a.75.75 0 0 0 1.5 0V20A8 8 0 0 0 20 12.75h1.28a.75.75 0 0 0 0-1.5ZM12 18.5a6.5 6.5 0 1 1 6.5-6.5 6.5 6.5 0 0 1-6.5 6.5" }, null, -1),
    _createElementVNode("path", { d: "M17 12a5 5 0 1 1-5-5 5 5 0 0 1 5 5" }, null, -1)
  ])))
}
export default { render: render }