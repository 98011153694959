import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.61 4.5c2.82 0 5.11 2.29 5.11 5.11s-2.29 5.11-5.11 5.11S4.5 12.43 4.5 9.61 6.79 4.5 9.61 4.5m0-1.5a6.61 6.61 0 1 0 .002 13.222A6.61 6.61 0 0 0 9.61 3" }, null, -1),
    _createElementVNode("path", { d: "M14.16 13.39c-.2 0-.39.08-.54.23-.3.3-.3.79 0 1.09l5.07 5.07c.15.15.35.23.54.23s.39-.08.54-.23c.3-.3.3-.79 0-1.09l-5.07-5.07a.78.78 0 0 0-.54-.23" }, null, -1)
  ])))
}
export default { render: render }