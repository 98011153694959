<template>
  <button
    type="button"
    :title="step.label"
    class="relative flex items-center justify-center py-1 text-xs"
    :class="{ 'pointer-events-none': !(upcoming || visited) }"
  >
    <div
      class="z-20 flex items-center justify-center rounded-full ring-2"
      :class="[
        cssClasses.indicator,
        {
          'bg-neutral text-on-neutral ring-neutral': !active,
          'ring-primary bg-light dark:bg-dark text-dark dark:text-light':
            active,
          'ring-primary bg-primary text-on-primary':
            !active && valid && visited,
        },
      ]"
    >
      <InlineSvg
        v-if="!active && valid && visited"
        :name="SVG_ICON.CHECK"
        class="h-4 w-4 fill-current"
      />
      <template v-else>
        {{ index }}
      </template>
    </div>

    <div
      class="absolute left-1/2 -translate-x-1/2 translate-y-full text-center"
      :class="cssClasses.label"
      v-text="step.label"
    />
  </button>
</template>

<script lang="ts" setup>
interface Step {
  blockingCount: number
  errorCount: number
  label: string
  name: string
  valid: Ref
}

interface Props {
  step: Step
  index: number
  active: boolean
  visited: boolean
  valid: boolean
  upcoming: boolean
  classes?: {
    indicator?: string | object | []
    label?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
})

const cssClasses = computed(() => {
  const { indicator, label } = props.classes

  return {
    indicator: indicator ?? 'h-6 w-6 sm:h-8 sm:w-8 text-sm',
    label: label ?? 'text-sm w-20 pt-1',
  }
})

defineOptions({
  name: 'MultiStepFormProgressStep',
})
</script>
