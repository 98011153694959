import { default as _91_46_46_46pageSlug_93LG4lLJqhN3Meta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateNhyw4j5S57Meta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue?macro=true";
import { default as indexCJ0k68dYufMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_938QCk4uobPOMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newhy3FklvIdoMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistFzmgm8r4VOMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexatx97yejl5Meta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue?macro=true";
import { default as edittXZmQgiovUMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as index0CglGobkEQMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addVvCelstvkpMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesqs53igwGy9Meta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as indexs7ugRCXRzuMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infosT9NTTcxq5RMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as programdF92BVjtupMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as _91showSlug_938worCYzwA3Meta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93lXY0zu0RBOMeta } from "/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexatx97yejl5Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edittXZmQgiovUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index0CglGobkEQMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addVvCelstvkpMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesqs53igwGy9Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93lXY0zu0RBOMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-01-23_14-01-25_9b95465/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  }
]