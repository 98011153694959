import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { viewBox: "0 0 24 24" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "18",
      height: "1.5",
      x: "3",
      y: "6.5",
      rx: ".75"
    }, null, -1),
    _createElementVNode("rect", {
      width: "18",
      height: "1.5",
      x: "3",
      y: "11.25",
      rx: ".75"
    }, null, -1),
    _createElementVNode("rect", {
      width: "18",
      height: "1.5",
      x: "3",
      y: "16",
      rx: ".75"
    }, null, -1)
  ])))
}
export default { render: render }