import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M2 12C2 6.481 6.481 2 12 2s10 4.481 10 10-4.481 10-10 10S2 17.519 2 12m18.5 0c0-4.691-3.809-8.5-8.5-8.5S3.5 7.309 3.5 12s3.809 8.5 8.5 8.5 8.5-3.809 8.5-8.5" }, null, -1),
    _createElementVNode("path", { d: "M11 17v-.53a1.001 1.001 0 0 1 2 0V17a1 1 0 0 1-2 0m0-3.999v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0" }, null, -1)
  ])))
}
export default { render: render }